import React from "react";
import { Row } from "react-bootstrap";

const ZohoContact = () => {
  return (
    <Row
      style={{
        justifyContent: "center",
        backgroundColor: "Highlight",
      }}
    >
      <iframe
        aria-label="Contact us"
        frameborder="0"
        style={{
          height: "800px",
          width: "99%",
          border: "none",
          borderWidth: 0,
        }}
        src="https://forms.zohopublic.in/viduraconsultingservices/form/Contactus/formperma/pnFxnemgxOvjatU6F7ZJRGOdk-PAyfIMf7GOJ5RorHo"
      ></iframe>
    </Row>
  );
};

export default ZohoContact;
