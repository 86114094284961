import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row } from "react-bootstrap";
import styles from "./Layout.module.css";

const Layout = () => {
  return (
    <Container fluid>
      <div className={styles.mainBody} style={{ marginBottom: "10px" }}>
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-K4TFSRMD"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
            title="googletagmanager"
          ></iframe>
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}
        <Row>
          <Header></Header>
        </Row>
        <Row>
          <Outlet />
        </Row>
      </div>
      <Row>
        <Footer />
      </Row>
    </Container>
  );
};

export default Layout;
