// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_mainBody__34xvn {
  min-height: calc(100vh - 161px);
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/shared/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".mainBody {\n  min-height: calc(100vh - 161px);\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainBody": `Layout_mainBody__34xvn`
};
export default ___CSS_LOADER_EXPORT___;
