import React from "react";
import Image1 from "../../../app_images/globe-cover.jpg";
import { Card, Row } from "react-bootstrap";
import styles from "./HomeCards.module.css";

const HomeCards = () => {
  return (
    <Card className="text-white" style={{ border: "0.5px" }}>
      <Card.ImgOverlay className={styles.homecardimage}>
        <Row>
          <Card.Text>DIVERGENT AT DESIGN</Card.Text>
        </Row>
        <Row>
          <Card.Text>DILIGENT AT DELIVERY</Card.Text>
        </Row>
      </Card.ImgOverlay>
      <Card.Img
        src={Image1}
        className={styles.imageCard}
        alt="Card image"
        variant="bottom"
      />
    </Card>
  );
};

export default HomeCards;
