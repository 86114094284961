import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./shared/Layout";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import "bootstrap/dist/css/bootstrap.min.css";
import Services from "./pages/Service";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import InProgress from "./pages/InProgress";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import CookieConsent from "react-cookie-consent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  const onLoad = () => {};

  return (
    <div className="App">
      <TawkMessengerReact
        propertyId="658178f907843602b803986f"
        widgetId="1hi0s14ku"
        onLoad={onLoad}
      />{" "}
      <CookieConsent>
        {" "}
        We use essential and analytical cookies to improve site functionality
        and enhance user experience. By using our website, you agree to our
        cookie usage.{" "}
      </CookieConsent>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/consulting" element={<InProgress />} />
            <Route path="/careers" element={<InProgress />} />
            <Route path="/case-studies" element={<InProgress />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
