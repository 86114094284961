// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Privacy_privacyContent__qfg2P {
  border-color: rgb(202, 202, 202);
  border-width: 0.5px;
  border-style: groove;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Privacy.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".privacyContent {\n  border-color: rgb(202, 202, 202);\n  border-width: 0.5px;\n  border-style: groove;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacyContent": `Privacy_privacyContent__qfg2P`
};
export default ___CSS_LOADER_EXPORT___;
