// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutUsCard_about__wHSd\\+ {
  border: none !important;
  background-color: white !important;
  font-family: "Roboto";
  font-weight: 900;
}

.AboutUsCard_content__uSqoa {
  font-family: Roboto;
  font-weight: 100;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/shared/Components/CustomCards/AboutUsCard.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kCAAkC;EAClC,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".about {\n  border: none !important;\n  background-color: white !important;\n  font-family: \"Roboto\";\n  font-weight: 900;\n}\n\n.content {\n  font-family: Roboto;\n  font-weight: 100;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": `AboutUsCard_about__wHSd+`,
	"content": `AboutUsCard_content__uSqoa`
};
export default ___CSS_LOADER_EXPORT___;
