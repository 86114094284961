// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_noborder__4\\+XpE {
  border: none !important;
}

.Footer_fixFooter__K1bOm {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--app-brand-color);
  padding: 20px;
}

.Footer_footerlistgroupitem__KZLc8 {
  position: relative;
  display: block;
  padding: 2px;
  color: var(--bs-list-group-color);
  text-decoration: none;
  border: none !important;
  text-align: left;
}

.Footer_footerLinks__kxueD {
  text-decoration: none;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/shared/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,QAAQ;EACR,wCAAwC;EACxC,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,iCAAiC;EACjC,qBAAqB;EACrB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".noborder {\n  border: none !important;\n}\n\n.fixFooter {\n  position: relative;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background-color: var(--app-brand-color);\n  padding: 20px;\n}\n\n.footerlistgroupitem {\n  position: relative;\n  display: block;\n  padding: 2px;\n  color: var(--bs-list-group-color);\n  text-decoration: none;\n  border: none !important;\n  text-align: left;\n}\n\n.footerLinks {\n  text-decoration: none;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noborder": `Footer_noborder__4+XpE`,
	"fixFooter": `Footer_fixFooter__K1bOm`,
	"footerlistgroupitem": `Footer_footerlistgroupitem__KZLc8`,
	"footerLinks": `Footer_footerLinks__kxueD`
};
export default ___CSS_LOADER_EXPORT___;
