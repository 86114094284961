import React from "react";
import { Container, Image } from "react-bootstrap";
import InProgressImage from "../app_images/inprogres.jpeg";

const InProgress = () => {
  return (
    <Container>
      <div>
        <Image src={InProgressImage} width={"100%"} height={"700px"} />
      </div>
    </Container>
  );
};

export default InProgress;
