import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import logoImage from "../imageFiles/Vidura_Logo.png";

const Header = () => {
  //BBD44F
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
          <Image
            src={logoImage}
            width={"140 px"}
            height={"50 px"}
            style={{ mixBlendMode: "multiply" }}
          ></Image>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav variant="underline" className="me-auto">
            <Nav.Link href="/about-us">Company</Nav.Link>
            <Nav.Link href="/services">Capabalities</Nav.Link>
            <Nav.Link href="/consulting">Consulting</Nav.Link>
            <Nav.Link href="/case-studies">Case Studies</Nav.Link>
            <Nav.Link href="/careers">Careers</Nav.Link>
            <Nav.Link href="/contact-us">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
