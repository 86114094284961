import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Row,
} from "react-bootstrap";
import styles from "./AboutUsCard.module.css";
import { Divider } from "@mui/material";

const AboutUsCard = () => {
  return (
    <Card className={styles.about}>
      <CardBody>
        <Row>
          <Col>
            <CardHeader className={styles.about}>
              <Divider>
                <h2>About us</h2>
              </Divider>
            </CardHeader>
          </Col>
        </Row>
        <Row>
          <div style={{ alignSelf: "center" }}>
            <CardText className={styles.content}>
              Welcome to Vidura, an esteemed partner in the realm of advanced IT
              consulting services. Our commitment to excellence is reflected in
              our comprehensive approach to guiding businesses through the
              nuanced landscape of technology. At Vidura, we specialize in
              providing tailored solutions across the entire spectrum of the
              product development life cycle, ensuring a seamless journey from
              conceptualization to deployment. Our client-centric ethos is the
              cornerstone of our philosophy. We understand that each business is
              unique, facing distinct challenges and opportunities. Therefore,
              our seasoned experts work closely with clients to gain a profound
              understanding of their objectives and operational intricacies.
              This collaborative approach allows us to craft bespoke strategies
              and solutions that align perfectly with the client's goals.
              <br />
              <br />
              In the realm of product development, Vidura excels in adhering to
              industry best practices and employing the latest methodologies.
              Our teams are adept at navigating the complexities of the
              development life cycle, ensuring that every phase is executed with
              precision and efficiency. Whether it's ideation, planning,
              development, testing, or deployment, Vidura's commitment to
              quality is unwavering. Transparency is a guiding principle at
              Vidura. We believe in maintaining an open line of communication
              throughout the entire project, providing clients with regular
              updates and insights. This ensures that our clients are not only
              informed but actively involved in the decision-making process.
              <br /> <br />
              Our reliability is not just a promise but a track record. Vidura
              has consistently delivered high-quality outcomes, exceeding
              industry benchmarks. We take pride in our meticulous attention to
              detail, ensuring that every solution we provide is not only
              technologically advanced but also aligned with the client's
              strategic vision. Choose Vidura for a transformative partnership
              where innovation meets precision, and success is redefined as a
              standard of excellence in the ever-evolving digital landscape.
              Together, let's embark on a journey of technological advancement
              and unparalleled success.
            </CardText>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AboutUsCard;
