// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconCard_card__Ne-Gv:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.IconCard_cardTitle__R4O-J {
  font-family: var(--app-font-family);
  font-weight: bold;
}

.IconCard_ethiosCard__oVbam {
  margin: "5px";
  border: "0";
  height: "300px";
}

.IconCard_ethiosCardMobile__r98kT {
  border: "0";
  height: "350px";
}

.IconCard_ethiosCardContentMobile__BIGQQ {
  justify-content: "left";
  font-family: "Roboto";
  font-size: "small";
  text-align: justify;
}

.IconCard_ethiosCardContent__GYZ3S {
  justify-content: "center";
  font-family: "Roboto";
  text-align: justify;
}
`, "",{"version":3,"sources":["webpack://./src/shared/Components/Home/IconCard.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,0EAA0E;AAC5E;;AAEA;EACE,mCAAmC;EACnC,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".card:hover {\n  transform: scale(1.02);\n  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);\n}\n\n.cardTitle {\n  font-family: var(--app-font-family);\n  font-weight: bold;\n}\n\n.ethiosCard {\n  margin: \"5px\";\n  border: \"0\";\n  height: \"300px\";\n}\n\n.ethiosCardMobile {\n  border: \"0\";\n  height: \"350px\";\n}\n\n.ethiosCardContentMobile {\n  justify-content: \"left\";\n  font-family: \"Roboto\";\n  font-size: \"small\";\n  text-align: justify;\n}\n\n.ethiosCardContent {\n  justify-content: \"center\";\n  font-family: \"Roboto\";\n  text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `IconCard_card__Ne-Gv`,
	"cardTitle": `IconCard_cardTitle__R4O-J`,
	"ethiosCard": `IconCard_ethiosCard__oVbam`,
	"ethiosCardMobile": `IconCard_ethiosCardMobile__r98kT`,
	"ethiosCardContentMobile": `IconCard_ethiosCardContentMobile__BIGQQ`,
	"ethiosCardContent": `IconCard_ethiosCardContent__GYZ3S`
};
export default ___CSS_LOADER_EXPORT___;
