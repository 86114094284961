// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomCard_noBorder__fMaBS {
  border: none;
  background-color: var(--background-color);
}

.CustomCard_noBorderMob__0LgoJ {
  padding: 10px !important;
  margin-bottom: 3%;
}

.CustomCard_cardFooter__iVcZG {
  padding: 0% !important;
  border: none;
  background-color: white;
  padding: 0%;
}

.CustomCard_cardHeader__lpCum {
  border: none;
  padding: 0%;
  background-color: white;
}

.CustomCard_cardImage__a6ZDw {
  /* width: var(--card-image-width); */
  /* height: var(--card-image-height); */
  width: 100%;
  height: 30rem;
}

.CustomCard_cardImageMob__c0WJl {
  /* width: var(--card-image-width); */
  /* height: var(--card-image-height); */
  width: 100%;
  height: 16rem;
  padding: 2%;
}

.CustomCard_cardContent__XC2ux {
  align-self: center;
}

.CustomCard_cardContentMob__3b6Ii {
  text-align: left;
  font-family: Roboto;
}

.CustomCard_cardHeadMob__Eynuw {
  font-size: larger;
  font-family: Roboto;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/shared/Components/CustomCards/CustomCard.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yCAAyC;AAC3C;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,sCAAsC;EACtC,WAAW;EACX,aAAa;AACf;;AAEA;EACE,oCAAoC;EACpC,sCAAsC;EACtC,WAAW;EACX,aAAa;EACb,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".noBorder {\n  border: none;\n  background-color: var(--background-color);\n}\n\n.noBorderMob {\n  padding: 10px !important;\n  margin-bottom: 3%;\n}\n\n.cardFooter {\n  padding: 0% !important;\n  border: none;\n  background-color: white;\n  padding: 0%;\n}\n\n.cardHeader {\n  border: none;\n  padding: 0%;\n  background-color: white;\n}\n\n.cardImage {\n  /* width: var(--card-image-width); */\n  /* height: var(--card-image-height); */\n  width: 100%;\n  height: 30rem;\n}\n\n.cardImageMob {\n  /* width: var(--card-image-width); */\n  /* height: var(--card-image-height); */\n  width: 100%;\n  height: 16rem;\n  padding: 2%;\n}\n\n.cardContent {\n  align-self: center;\n}\n\n.cardContentMob {\n  text-align: left;\n  font-family: Roboto;\n}\n\n.cardHeadMob {\n  font-size: larger;\n  font-family: Roboto;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noBorder": `CustomCard_noBorder__fMaBS`,
	"noBorderMob": `CustomCard_noBorderMob__0LgoJ`,
	"cardFooter": `CustomCard_cardFooter__iVcZG`,
	"cardHeader": `CustomCard_cardHeader__lpCum`,
	"cardImage": `CustomCard_cardImage__a6ZDw`,
	"cardImageMob": `CustomCard_cardImageMob__c0WJl`,
	"cardContent": `CustomCard_cardContent__XC2ux`,
	"cardContentMob": `CustomCard_cardContentMob__3b6Ii`,
	"cardHeadMob": `CustomCard_cardHeadMob__Eynuw`
};
export default ___CSS_LOADER_EXPORT___;
