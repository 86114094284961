import React from "react";
import CustomCard from "../shared/Components/CustomCards/CustomCard";
import { Helmet } from "react-helmet-async";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>VCS - Services</title>
        <meta
          name="description"
          content="Automation, Hyderabad - Web API Development, Seattle - Custom Software Development US, US Software Engineers, Hyderabad - Software Develoment, Seattle - Enterprise Software Solutions, Web Development Servics, DevOps, Mobile App Development, Outsourcing Hyderabad, Block Chain Development, UX/UI Designs, IoT Application, Hyderabad, US, Testing Solutions, Software Testing, Automation, Full Stack Development, Data Visualization, Data warehousing, Machine Learning, Microsoft Dot Net Development, Robotic Services, Microsoft Managed Services, Microsoft Managed IT Services, Microsoft Cloud Services, Microsoft Cloud Support, Microsoft Azure Services, Managed Azure Solutions, Office 365 Development, Microsoft Infrastruction Management, Microsoft Security Services, Microsoft 365 Consulting."
        />{" "}
        <link rel="canonical" href="https://viduraconsulting.com/services" />
      </Helmet>
      <CustomCard />
    </>
  );
};

export default Services;
