import {
  LocalFireDepartment,
  Public,
  Air,
  Water,
  Star,
} from "@mui/icons-material";
import { React } from "react";
import { Card, Row } from "react-bootstrap";
import styles from "./IconCard.module.css";
import useScreenSize from "../../../functions/useScreenSize";

const IconCard = ({ iconName, caption, text }) => {
  const screenSize = useScreenSize();

  const getIconbyName = (name) => {
    switch (name) {
      case "LocalFireDepartment":
        return <LocalFireDepartment sx={{ fontSize: "75px" }} />;
      case "Water":
        return <Water sx={{ fontSize: "75px" }} />;
      case "Air":
        return <Air sx={{ fontSize: "75px" }} />;
      case "Public":
        return <Public sx={{ fontSize: "75px" }} />;
      case "Star":
        return <Star sx={{ fontSize: "75px" }} />;
      default:
        return <></>;
    }
  };
  return (
    <Card
      className={`p-3 m-1 bg-white rounded ${
        screenSize.width > 900 ? styles.ethiosCard : styles.ethiosCardMobile
      }`}
      style={{ height: screenSize.width > 900 ? "44vh" : "50vh" }}
    >
      <Card.Body>
        <Row style={{ justifyContent: "center" }}>
          {getIconbyName(iconName)}
        </Row>
        <Row
          style={{
            justifyContent: "center",
            fontWeight: "bold",
            fontFamily: "Roboto",
            fontSize: "20px",
          }}
        >
          {caption}
        </Row>
        <Row
          className={`${
            screenSize.width > 900
              ? styles.ethiosCardContent
              : styles.ethiosCardContentMobile
          }`}
        >
          {text}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default IconCard;
