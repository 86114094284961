import React from "react";
import AboutUsCard from "../shared/Components/CustomCards/AboutUsCard";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>VCS - Company</title>
        <link rel="canonical" href="https://viduraconsulting.com/about-us" />
        <meta
          name="description"
          content="Vidura consulting services, Software Company, IT Consulting services, Product Development, IT Services, Softwate Development strategy, leadership - Hyderabad, leadership - US"
        />
      </Helmet>
      <AboutUsCard></AboutUsCard>
    </>
  );
};

export default AboutUs;
