// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  --card-image-width: "700px";
  --card-image-height: "300px";
  --font-style: "Roboto Thin";
  --app-brand-color: rgb(193, 217, 82);
  --app-font-family: "TeX Gyre Adventor";
  --app-header-font: "Roboto";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list-group-item {
  border-radius: 0;
}

footer {
  margin-top: auto;
}
.carousel-indicators button {
  height: 10px !important;
  width: 10px !important;
  margin: 0 10px !important;
  border-radius: 100%;
  background-color: var(--app-brand-color) !important;
}

.carousel-control-prev-icon {
  background-color: var(--app-brand-color) !important;
}

.carousel-control-next-icon {
  background-color: var(--app-brand-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,2BAA2B;EAC3B,oCAAoC;EACpC,sCAAsC;EACtC,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,mDAAmD;AACrD;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,mDAAmD;AACrD","sourcesContent":["* {\n  --card-image-width: \"700px\";\n  --card-image-height: \"300px\";\n  --font-style: \"Roboto Thin\";\n  --app-brand-color: rgb(193, 217, 82);\n  --app-font-family: \"TeX Gyre Adventor\";\n  --app-header-font: \"Roboto\";\n}\n\n.App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.list-group-item {\n  border-radius: 0;\n}\n\nfooter {\n  margin-top: auto;\n}\n.carousel-indicators button {\n  height: 10px !important;\n  width: 10px !important;\n  margin: 0 10px !important;\n  border-radius: 100%;\n  background-color: var(--app-brand-color) !important;\n}\n\n.carousel-control-prev-icon {\n  background-color: var(--app-brand-color) !important;\n}\n\n.carousel-control-next-icon {\n  background-color: var(--app-brand-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
