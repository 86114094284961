// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ethios_hideLabel__lTvyw {
  display: none;
}

.Ethios_button__bar__iNvhu {
  display: inline;
  vertical-align: middle;
  margin: auto;
  padding: 0.5rem 0 0 0;
  background: white;
  border-radius: 40px;
  width: 250px;
  text-align: center;
}

.Ethios_button__bar__iNvhu li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.Ethios_button__bar__iNvhu li button {
  border: none;
  background: rgb(184, 184, 184);
  color: transparent;
  cursor: pointer;
  height: 8px;
  width: 20px;
  border-radius: 7.5px;
}

.Ethios_button__bar__iNvhu li.slick-active button {
  background-color: var(--app-brand-color);
}

.Ethios_button__bar__iNvhu li button:hover,
.Ethios_button__bar__iNvhu li button:focus {
  background: var(--app-brand-color);
  outline: 0;
}

.Ethios_slick-slide__Jx5Ir img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/shared/Components/Home/Ethios.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,qBAAqB;EACrB,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,8BAA8B;EAC9B,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;;EAEE,kCAAkC;EAClC,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,cAAc;EACd,YAAY;AACd","sourcesContent":[".hideLabel {\n  display: none;\n}\n\n.button__bar {\n  display: inline;\n  vertical-align: middle;\n  margin: auto;\n  padding: 0.5rem 0 0 0;\n  background: white;\n  border-radius: 40px;\n  width: 250px;\n  text-align: center;\n}\n\n.button__bar li {\n  list-style: none;\n  cursor: pointer;\n  display: inline-block;\n  margin: 0 3px;\n  padding: 0;\n}\n\n.button__bar li button {\n  border: none;\n  background: rgb(184, 184, 184);\n  color: transparent;\n  cursor: pointer;\n  height: 8px;\n  width: 20px;\n  border-radius: 7.5px;\n}\n\n.button__bar :global(li.slick-active) button {\n  background-color: var(--app-brand-color);\n}\n\n.button__bar li button:hover,\n.button__bar li button:focus {\n  background: var(--app-brand-color);\n  outline: 0;\n}\n\n.slick-slide img {\n  border: 5px solid #fff;\n  display: block;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hideLabel": `Ethios_hideLabel__lTvyw`,
	"button__bar": `Ethios_button__bar__iNvhu`,
	"slick-slide": `Ethios_slick-slide__Jx5Ir`
};
export default ___CSS_LOADER_EXPORT___;
