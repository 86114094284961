import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./Privacy.module.css";
import useScreenSize from "../functions/useScreenSize";

const Privacy = () => {
  const screenSize = useScreenSize();

  return (
    <Container
      fluid
      style={screenSize.width > 900 ? { margin: "15px" } : { marginTop: "5px" }}
    >
      <Row style={{ justifyContent: "center" }}>
        <Col
          md={screenSize.width > 900 ? 10 : 6}
          className={styles.privacyContent}
        >
          <Card style={{ border: "none" }}>
            <h2 style={{ fontFamily: "Roboto", textDecoration: "underline" }}>
              Privacy Policy
            </h2>
            <br />
            <div>
              <p style={{ textAlign: "left", fontFamil: "Arial" }}>
                Thank you for visiting the Vidura Consulting Company website
                ("Vidura Website"). This Privacy Policy outlines how we collect,
                use, and protect your personal information through our website.
                By using our website, you consent to the practices described in
                this policy.
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>1. Information We Collect Personal Information:</strong>
              </p>
              <p style={{ textAlign: "left" }}>
                When you visit our website, we may collect personal information
                such as your name, email address, and any other information
                voluntarily provided through forms or correspondence. Usage
                Information: We collect information about your interactions with
                our website, including IP addresses, browser details, and other
                analytics data. Cookies: We use cookies to enhance your
                experience on our website. Cookies are small files stored on
                your device that help us analyze usage patterns, customize
                content, and improve functionality.
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>2. How We Use Your Information</strong>
              </p>
              <p style={{ textAlign: "left" }}>
                We use the collected information for the following purposes:
                <ul>
                  <li>To provide and personalize our website content. </li>
                  <li>
                    To analyze and improve the performance and functionality of
                    our website.
                  </li>
                  <li>
                    To respond to inquiries and requests submitted through our
                    website.
                  </li>
                </ul>
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>3. Information Sharing</strong>
              </p>
              <text style={{ textAlign: "left" }}>
                We do not sell, trade, or disclose your personal information to
                third parties for marketing purposes. However, we may share
                information in the following circumstances:
                <ul>
                  <li>
                    With our service providers who assist in the operation of
                    our website.
                  </li>
                  <li>
                    In connection with a merger, acquisition, or other business
                    transaction.
                  </li>
                  <li>
                    To comply with legal obligations and regulatory
                    requirements.
                  </li>
                </ul>
              </text>
              <p style={{ textAlign: "left" }}>
                <strong>4. Third-Party Links</strong>
              </p>
              <p style={{ textAlign: "left" }}>
                Our website may contain links to third-party websites. We are
                not responsible for the privacy practices or content of these
                external sites. We encourage you to review the privacy policies
                of those websites.
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>5. Security Measures</strong>
              </p>
              <p style={{ textAlign: "left" }}>
                We implement reasonable security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, and destruction. While we strive to protect your
                information, we cannot guarantee absolute security due to the
                inherent nature of the internet.
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>6. Your Choices</strong>
              </p>
              <p style={{ textAlign: "left" }}>
                You have the right to control the information you provide to us.
                You can choose to disable cookies through your browser settings
                or opt out of receiving communications from us. However, some
                website features may be affected by these choices.
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>7. Changes to the Privacy Policy</strong>
              </p>
              <p style={{ textAlign: "left" }}>
                We may update this Privacy Policy to reflect changes in our
                practices. The revised policy will be effective immediately upon
                posting on our website. Please review this Privacy Policy
                periodically for any updates.
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>8. Contact Information</strong>
              </p>
              <p style={{ textAlign: "left" }}>
                If you have questions or concerns about our Privacy Policy or
                data practices, please contact us at [insert contact details].
              </p>
              <p style={{ textAlign: "left" }}>
                By using our website, you agree to the terms of this Privacy
                Policy.
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Privacy;
