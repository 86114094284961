import { React } from "react";
import IconCard from "./IconCard";
import useScreenSize from "../../../functions/useScreenSize";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Ethios.module.css";

const Elements = [
  {
    icon: "LocalFireDepartment",
    caption: "Creativity",
    text: "Fire embodies passion and innovation. In IT services, creativity acts as the spark that ignites novel solutions. Much like the transformative nature of fire, creative thinking is essential for pushing the boundaries of technology and finding inventive solutions to complex challenges",
  },
  {
    icon: "Public",
    caption: "Stability",
    text: "Earth symbolizes stability and foundation. In IT services, a solid technological foundation is essential for stability. Like the firm ground beneath our feet, a stable IT infrastructure ensures reliability, security, and consistency in delivering services.",
  },
  {
    icon: "Star",
    caption: "Sustainability",
    text: " Space signifies expansiveness and interconnectedness. Sustainability in IT services involves considering the broader ecosystem, ensuring that technological advancements align with environmental and ethical considerations. It's about creating a harmonious and interconnected digital landscape",
  },
  {
    icon: "Air",
    caption: "Innovation",
    text: "Air represents freedom and constant movement. Similarly, innovation in IT services is a continuous process of exploration and improvement. Like the ever-moving air, IT services must embrace change and innovation to stay ahead in the dynamic and evolving landscape of technology.",
  },
  {
    icon: "Water",
    caption: "Agility",
    text: "Water's adaptability is reflected in the agility required for IT services. Just as water takes the shape of its container, agile IT services must be flexible and responsive to changes. The ability to navigate swiftly in the ever-changing technological currents is crucial for success.",
  },
];

const Ethios = () => {
  const screenSize = useScreenSize();

  const getEthios = () => {
    return Elements.map((item, index) => {
      return (
        <IconCard
          key={index + item.icon}
          iconName={item.icon}
          caption={item.caption}
          text={item.text}
        />
      );
    });
  };
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: screenSize.width > 900 ? 3 : 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    dotsClass: `${styles.button__bar}`,
  };
  return (
    <>
      <div className={styles.customSlider}>
        <Slider {...settings}>{getEthios()}</Slider>
      </div>
    </>
  );
};

export default Ethios;
