import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import useScreenSize from "../functions/useScreenSize";
import styles from "./Terms.module.css";

const Terms = () => {
  const screenSize = useScreenSize();

  return (
    <Container
      fluid
      style={screenSize.width > 900 ? { margin: "15px" } : { marginTop: "5px" }}
    >
      <Row style={{ justifyContent: "center" }}>
        <Col
          md={screenSize.width > 900 ? 10 : 6}
          className={styles.privacyContent}
        >
          <Card style={{ border: "none" }}>
            <h2 style={{ fontFamily: "Roboto", textDecoration: "underline" }}>
              Terms and Conditions
            </h2>
            <br />
            <div>
              <p style={{ textAlign: "left", fontFamil: "Arial" }}>
                By accessing and using the Vidura Consulting Services Company
                website ("Vidura Website"), you agree to comply with and be
                bound by the following terms and conditions. If you do not agree
                with these terms, please refrain from using our website.
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>1. Use of Website:</strong>
              </p>
              <article>
                <ol type="a">
                  <li style={{ textAlign: "left" }}>
                    You agree to use the Vidura Website for lawful purposes only
                    and in a manner consistent with all applicable laws and
                    regulations.
                  </li>
                  <li style={{ textAlign: "left" }}>
                    You shall not engage in any activity that interferes with
                    the proper functioning of the website or that may compromise
                    its security.
                  </li>
                </ol>
              </article>
              <article>
                <p style={{ textAlign: "left" }}>
                  <strong>2. Intellectual Property</strong>
                </p>
                <ol type="a">
                  <li style={{ textAlign: "left" }}>
                    The content on the Vidura Website, including text, graphics,
                    logos, and images, is the property of Vidura IT Consulting
                    Company and is protected by intellectual property laws.
                  </li>
                  <li style={{ textAlign: "left" }}>
                    You may not reproduce, distribute, modify, or otherwise use
                    the content for any commercial purpose without the express
                    written consent of Vidura IT Consulting Company.
                  </li>
                </ol>
              </article>
              <article>
                <p style={{ textAlign: "left" }}>
                  <strong>3. User Submissions</strong>
                </p>
                <ol type="a">
                  <li style={{ textAlign: "left" }}>
                    If you submit any content, including but not limited to
                    feedback, comments, or suggestions, you grant Vidura
                    Consulting Company a non-exclusive, royalty-free, perpetual,
                    irrevocable, and fully sublicensable right to use,
                    reproduce, modify, adapt, publish, translate, create
                    derivative works from, distribute, and display such content.
                  </li>
                  <li style={{ textAlign: "left" }}>
                    You warrant that any content you submit does not violate the
                    rights of third parties and is not unlawful, defamatory, or
                    obscene.
                  </li>
                </ol>
              </article>
              <article>
                <p style={{ textAlign: "left" }}>
                  <strong>4. Links to Third-Party Websites</strong>
                </p>
                <ol type="a">
                  <li style={{ textAlign: "left" }}>
                    The Vidura Website may contain links to third-party
                    websites. These links are provided for convenience only, and
                    Vidura IT Consulting Company is not responsible for the
                    content or privacy practices of these external sites.
                  </li>
                  <li style={{ textAlign: "left" }}>
                    You access third-party websites at your own risk and should
                    review their terms and conditions and privacy policies.
                  </li>
                </ol>
              </article>
              <article>
                <p style={{ textAlign: "left" }}>
                  <strong>5. Limitation of Liability</strong>
                </p>
                <ol type="a">
                  <li style={{ textAlign: "left" }}>
                    Vidura Consulting Company is not liable for any direct,
                    indirect, incidental, consequential, or punitive damages
                    arising out of your use or inability to use the Vidura
                    Website.
                  </li>
                  <li style={{ textAlign: "left" }}>
                    Vidura Consulting Company does not guarantee the accuracy,
                    completeness, or timeliness of the information on the
                    website.
                  </li>
                </ol>
              </article>
              <article>
                <p style={{ textAlign: "left" }}>
                  <strong>6. Modification of Terms</strong>
                </p>
                <p style={{ textAlign: "left" }}>
                  Vidura Consulting Company reserves the right to modify these
                  terms and conditions at any time. Changes will be effective
                  immediately upon posting on the Vidura Website. Your continued
                  use of the website constitutes acceptance of the modified
                  terms.
                </p>
              </article>
              <article>
                <p style={{ textAlign: "left" }}>
                  <strong>6. Governing Law</strong>
                </p>
                <p style={{ textAlign: "left" }}>
                  These terms and conditions are governed by and construed by
                  the laws of [insert jurisdiction]. Any disputes arising out of
                  or in connection with these terms shall be subject to the
                  exclusive jurisdiction of the courts in [insert jurisdiction].
                </p>
              </article>
              <article>
                <p style={{ textAlign: "left" }}>
                  <strong>6. Contact Information</strong>
                </p>
                <p style={{ textAlign: "left" }}>
                  For questions or concerns regarding these terms and
                  conditions, please contact us at{" "}
                  <a href="mailto:webmaster@example.com">
                    info@viduraconsulting.com
                  </a>
                </p>
              </article>
              <p style={{ textAlign: "left" }}>
                By using the Vidura Website, you agree to abide by these terms
                and conditions.
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Terms;
