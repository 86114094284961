// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeCards_homecardimage__Emzzq {
  margin-left: 66%;
  margin-top: 10%;
  font-size: xx-large;
  font-weight: 900;
  color: var(--app-brand-color);
  font-family: "Roboto";
}
@media screen and (max-width: 600px) {
  .HomeCards_homecardimage__Emzzq {
    margin-left: 48%;
    margin-top: 10%;
    font-size: small;
    font-weight: 900;
    color: var(--app-brand-color);
    font-family: "Roboto";
  }
  .HomeCards_imageCard__-RXow {
    width: 100%;
    height: 140px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/Components/Home/HomeCards.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,6BAA6B;EAC7B,qBAAqB;AACvB;AACA;EACE;IACE,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,6BAA6B;IAC7B,qBAAqB;EACvB;EACA;IACE,WAAW;IACX,aAAa;EACf;AACF","sourcesContent":[".homecardimage {\n  margin-left: 66%;\n  margin-top: 10%;\n  font-size: xx-large;\n  font-weight: 900;\n  color: var(--app-brand-color);\n  font-family: \"Roboto\";\n}\n@media screen and (max-width: 600px) {\n  .homecardimage {\n    margin-left: 48%;\n    margin-top: 10%;\n    font-size: small;\n    font-weight: 900;\n    color: var(--app-brand-color);\n    font-family: \"Roboto\";\n  }\n  .imageCard {\n    width: 100%;\n    height: 140px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homecardimage": `HomeCards_homecardimage__Emzzq`,
	"imageCard": `HomeCards_imageCard__-RXow`
};
export default ___CSS_LOADER_EXPORT___;
