// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Terms_privacyContent__gAa2V {
  border-color: rgb(187, 179, 179);
  border-width: 0.5px;
  border-style: groove;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Terms.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".privacyContent {\n  border-color: rgb(187, 179, 179);\n  border-width: 0.5px;\n  border-style: groove;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacyContent": `Terms_privacyContent__gAa2V`
};
export default ___CSS_LOADER_EXPORT___;
