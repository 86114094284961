import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./Home.module.css";
import HomeCards from "../shared/Components/Home/HomeCards";
import ValuesCard from "../shared/Components/Home/ValuesCard";
import Ethios from "../shared/Components/Home/Ethios";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Vidura Consulting Services</title>
        <link rel="canonical" href="https://viduraconsulting.com" />
        <meta
          name="description"
          content="Vidura Company, Software Company, Hyderabad - Consulting Services, Software Development, Vidura, Consulting, IT Firm, Development, Software Engineering, Automation,  Web API Development, Seattle - Custom Software Development US, US Software Engineers, Hyderabad - Software Develoment, Seattle - Enterprise Software Solutions, Web Development Servics, DevOps, Mobile App Development, Outsourcing Hyderabad, Block Chain Development, UX/UI Designs,Hyderabad, US, Testing Solutions, Software Testing, Automation, Full Stack Development, Data Visualization, Machine Learning, Microsoft Dot Net Development, Robotic Services, Microsoft Managed Services, Microsoft Managed IT Services, Microsoft Cloud Services, Microsoft Cloud Support, Microsoft Azure Services, Managed Azure Solutions, Office 365 Development, Microsoft Infrastruction Management, Microsoft Security Services, Microsoft 365 Consulting."
        />
      </Helmet>
      <div>
        <Row>
          <HomeCards></HomeCards>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div
              className={`${styles.title_lines_function} ${styles.titleFunctionDiv}`}
            >
              Our Core Functions
            </div>
          </Col>
        </Row>
        <Row>
          <div style={{ marginTop: "4.8px", paddingBottom: "0" }}>
            <ValuesCard />
          </div>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div
              className={`${styles.title_lines_ethios} ${styles.titleEthiosDiv}`}
            >
              Our Elements & Ethos
            </div>
          </Col>
        </Row>
        <Ethios />
      </div>
    </>
  );
};

export default Home;
