import React from "react";
import { Col, Container, Image, ListGroup, Navbar, Row } from "react-bootstrap";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import logoImage from "../imageFiles/Vidura_Logo.png";

const FooterItems = [
  { name: "Company", url: "/about-us" },
  { name: "Capability", url: "/services" },
  { name: "Consulting", url: "/consulting" },
  { name: "Case Studies", url: "/case-studies" },
  { name: "Careers", url: "/careers" },
  { name: "Contact", url: "/contact-us" },
];

const Footer = () => {
  const date = new Date();
  const getListItems = (startIndex, endIndex) => {
    return FooterItems.map((item, index) => {
      if (index >= startIndex && index <= endIndex)
        return (
          <li
            key={index}
            className={`${styles.footerlistgroupitem} ${styles.noborder}`}
          >
            <Link to={item.url} key={index} className={styles.footerLinks}>
              {item.name}
            </Link>
          </li>
        );
      return "";
    });
  };
  return (
    <footer className={`${styles.fixFooter}`}>
      <Container>
        <Row md={2}>
          <Col md={3}>
            <Navbar.Brand href="/">
              <Image
                src={logoImage}
                width={"140 px"}
                height={"50 px"}
                style={{ mixBlendMode: "multiply" }}
              ></Image>
            </Navbar.Brand>
          </Col>
          <Col md={3}>
            <ListGroup as={"ul"}>{getListItems(0, 2)}</ListGroup>
          </Col>
          <Col md={3}>
            <ListGroup as={"ul"}>{getListItems(3, 5)}</ListGroup>
          </Col>
          <Col md={3}>
            <ListGroup>
              <li
                className={`${styles.footerlistgroupitem} ${styles.noborder}`}
              >
                <Link to={"/privacy-policy"} className={styles.footerLinks}>
                  Privacy Policy
                </Link>
              </li>
              <li
                className={`${styles.footerlistgroupitem} ${styles.noborder}`}
              >
                <Link
                  to={"/terms-and-conditions"}
                  className={styles.footerLinks}
                >
                  Terms & Conditions
                </Link>
              </li>
            </ListGroup>
          </Col>
        </Row>
        <Row style={{ padding: "10px" }}>
          <Col>
            <span className="copyright quick-links">
              Copyright &copy; {date.getFullYear()} &nbsp;
              <b>Vidura Consulting Services</b>
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
