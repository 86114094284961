// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValuesCard_card__mkC86:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.ValuesCard_cardTitle__TolIM {
  font-family: var(--app-font-family);
  font-weight: bold;
}

.ValuesCard_ethiosContent__rZWpl {
  font-size: "small";
  text-align: "justify";
  font-style: "Roboto";
}
`, "",{"version":3,"sources":["webpack://./src/shared/Components/Home/ValuesCard.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,0EAA0E;AAC5E;;AAEA;EACE,mCAAmC;EACnC,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;AACtB","sourcesContent":[".card:hover {\n  transform: scale(1.02);\n  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);\n}\n\n.cardTitle {\n  font-family: var(--app-font-family);\n  font-weight: bold;\n}\n\n.ethiosContent {\n  font-size: \"small\";\n  text-align: \"justify\";\n  font-style: \"Roboto\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ValuesCard_card__mkC86`,
	"cardTitle": `ValuesCard_cardTitle__TolIM`,
	"ethiosContent": `ValuesCard_ethiosContent__rZWpl`
};
export default ___CSS_LOADER_EXPORT___;
