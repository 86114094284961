import { React } from "react";
import { Card, CardGroup, Col, Row } from "react-bootstrap";
import styles from "./ValuesCard.module.css";
import EngineeringIcon from "@mui/icons-material/SettingsSuggest";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ThunderstormOutlinedIcon from "@mui/icons-material/CloudDone";
import { Fade } from "react-awesome-reveal";

const ValuesCard = () => {
  return (
    <Row>
      <CardGroup>
        <Card
          className={`shadow p-3 mb-5 bg-white rounded ${styles.card}`}
          style={{ margin: "5px", border: "0" }}
        >
          <Card.Body style={{ border: "0px" }}>
            <Card.Text>
              <Row style={{ margin: "5px" }}>
                <Col sm="4">
                  <EngineeringIcon sx={{ fontSize: 120 }} />
                </Col>
                <Col sm="8" style={{ alignSelf: "center" }}>
                  <Row>
                    <h5>Srishti </h5>
                  </Row>
                  <Row>
                    <div style={{ fontSize: "12px" }}>
                      Strategic Design Consulting
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Fade delay={750} direction="left">
                  <div style={{ fontSize: "small", textAlign: "justify" }}>
                    Embark on the pinnacle of strategic design consulting with
                    Sristi, a paramount component within our suite of
                    distinguished IT consulting services. Here, our seasoned
                    design consultants meticulously formulate the architectural
                    foundation for sophisticated software solutions. Sristi
                    prides itself on achieving an exquisite equilibrium between
                    aesthetics and functionality, ensuring that each design
                    element seamlessly aligns with the overarching client
                    vision. Through an unwavering commitment to design
                    excellence, Sristi establishes the bedrock for a successful
                    IT product journey.{" "}
                  </div>
                </Fade>
              </Row>
            </Card.Text>
          </Card.Body>
          <Card.Footer style={{ border: "0px", backgroundColor: "white" }}>
            <small className="text-muted"></small>
          </Card.Footer>
        </Card>
        <Card
          className={`shadow p-3 mb-5 bg-white rounded ${styles.card}`}
          style={{ margin: "5px", border: "0" }}
        >
          <Card.Body style={{ border: "0px" }}>
            <Card.Text>
              <Row style={{ margin: "5px" }}>
                <Col sm="4">
                  <TipsAndUpdatesOutlinedIcon sx={{ fontSize: 120 }} />
                </Col>
                <Col sm="8" style={{ alignSelf: "center" }}>
                  <Row>
                    <h5>Sthithi </h5>
                  </Row>
                  <Row>
                    <div style={{ fontSize: "12px" }}>
                      Agile Development Consulting
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Fade delay={750} direction="up">
                  <div style={{ fontSize: "small", textAlign: "justify" }}>
                    Step into the realm of agile development consultancy with
                    Sthithi, a nucleus within our comprehensive portfolio of IT
                    consulting services. Here, Sthithi excels in translating
                    intricate designs into robust, high-performance software
                    solutions. Our adept development consultants leverage
                    industry-leading methodologies to meticulously code, test,
                    and refine, delivering a product that epitomizes resilience
                    and operational efficiency. Sthithi stands as the crucible
                    where visionary designs evolve into dynamic, responsive
                    software applications, embodying the quintessence of
                    effective IT product development.
                  </div>
                </Fade>
              </Row>
            </Card.Text>
          </Card.Body>
          <Card.Footer style={{ border: "0px", backgroundColor: "white" }}>
            <small className="text-muted"></small>
          </Card.Footer>
        </Card>
        <Card
          className={`shadow p-3 mb-5 bg-white rounded ${styles.card}`}
          style={{ margin: "5px", border: "0" }}
        >
          <Card.Body style={{ border: "0px" }}>
            <Card.Text>
              <Row style={{ margin: "5px" }}>
                <Col sm="4">
                  <ThunderstormOutlinedIcon sx={{ fontSize: 120 }} />
                </Col>
                <Col sm="8" style={{ alignSelf: "center" }}>
                  <Row>
                    <h5>Laya</h5>
                  </Row>
                  <Row>
                    <div style={{ fontSize: "12px" }}>
                      Deployment Excellence in IT Consulting Services
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Fade delay={750} direction="right">
                  <div style={{ fontSize: "small", textAlign: "justify" }}>
                    Experience deployment excellence with Laya, the consummate
                    phase in our holistic suite of IT consulting services. Laya
                    orchestrates a seamless transition from development to
                    operational deployment, implementing a meticulously crafted
                    strategy. Our deployment specialists work assiduously to
                    integrate the software seamlessly into existing client
                    infrastructures, minimizing downtime and optimizing overall
                    system performance. With Laya at the forefront, witness the
                    realization of your software solution, poised to empower
                    your enterprise in the dynamic landscape of digital
                    transformation.
                  </div>
                </Fade>
              </Row>
            </Card.Text>
          </Card.Body>
          <Card.Footer style={{ border: "0px", backgroundColor: "white" }}>
            <small className="text-muted"></small>
          </Card.Footer>
        </Card>
      </CardGroup>
    </Row>
  );
};

export default ValuesCard;
