import React from "react";
// import Contact from "../shared/Components/Forms/Contact";
import { Helmet } from "react-helmet-async";
import ZohoContact from "../shared/Components/Forms/ZohoContact";

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>VCS - Services</title>
        <meta
          name="Company Details"
          content="Contact us, Vidura Contact Form, IT Query, Service request, Software enquiry, product development enquiry, contact software company - hyderabad, contact software - US"
        />{" "}
        <link rel="canonical" href="https://viduraconsulting.com/services" />
      </Helmet>
      <ZohoContact />
    </>
  );
};

export default ContactUs;
