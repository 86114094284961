import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardImg,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import styles from "./CustomCard.module.css";
import { Fade } from "react-awesome-reveal";
import useScreenSize from "../../../functions/useScreenSize";

const cardData = [
  {
    imageSrc:
      "https://res.cloudinary.com/dffy9jfxj/image/upload/v1704981801/Vidura/UI_UX_k9heo6.jpg",
    title: "UI/UX Experience",
    content:
      "Crafting captivating digital experiences that resonate with your audience. From wireframes to stunning interfaces, we elevate user interactions.",
  },
  {
    imageSrc:
      "https://res.cloudinary.com/dffy9jfxj/image/upload/v1704981801/Vidura/Product_Development_1_iuzp7i.jpg",
    content:
      "Innovative applications tailored to your needs. Our development expertise spans web, mobile, and beyond, ensuring seamless user experiences",
    title: "Product Development",
  },
  {
    imageSrc:
      "https://res.cloudinary.com/dffy9jfxj/image/upload/v1704981801/Vidura/Dev_Ops_1_vquazi.jpg",
    content:
      "Accelerate your development lifecycle with DevOps, ensure robustness with comprehensive testing, and unlock business insights through advanced data analytics.",
    title: "DevOps, Testing, Data Analytics",
  },
];

const CustomCard = () => {
  const screenSize = useScreenSize();

  const cardData = JSON.parse(process.env.REACT_APP_SERVICES);

  const prepareCardData = () => {
    return cardData.map((card, index) => {
      return (
        <Card
          key={index}
          className={
            screenSize.width > 900 ? styles.noBorder : styles.noBorderMob
          }
        >
          <CardBody
            className={`${screenSize.width > 900 ? styles.noBorder : ""}`}
          >
            {CardDetails(card, index)}
          </CardBody>

          <CardFooter className={styles.cardFooter}></CardFooter>
        </Card>
      );
    });
  };

  const CardDetails = (card, index) => {
    if (screenSize.width > 900) {
      if (index % 2 === 0) {
        return (
          <Row>
            <Col sm={7}>
              <CardImg
                src={`${card.imageSrc}`}
                className={styles.cardImage}
              ></CardImg>
            </Col>
            <Col sm={5} className={styles.cardContent}>
              <Fade direction={index % 2 === 0 ? "up" : "down"} delay={700}>
                <div>
                  <h3>{card.title}</h3>
                  <Fade
                    direction={index % 2 === 0 ? "up" : "down"}
                    delay={1200}
                  >
                    <p>{card.content}</p>
                  </Fade>
                </div>
              </Fade>
            </Col>
          </Row>
        );
      } else
        return (
          <Row>
            <Col sm={5} className={styles.cardContent}>
              <div>
                <h3>{card.title}</h3>
                <p>{card.content}</p>
              </div>
            </Col>
            <Col sm={7}>
              <CardImg
                src={`${card.imageSrc}`}
                className={styles.cardImage}
              ></CardImg>
            </Col>
          </Row>
        );
    } else {
      return (
        <>
          <Row>
            <CardImg
              src={`${card.imageSrc}`}
              className={styles.cardImageMob}
            ></CardImg>
          </Row>
          <Row className={styles.cardContent}>
            <Fade direction={"up"} delay={700}>
              <div>
                <h3 className={styles.cardHeadMob}>{card.title}</h3>
                <Fade direction={index % 2 === 0 ? "up" : "down"} delay={1200}>
                  <p className={styles.cardContentMob}>{card.content}</p>
                </Fade>
              </div>
            </Fade>
          </Row>
        </>
      );
    }
  };
  return (
    <Container>
      {cardData !== null && cardData.length > 0 ? prepareCardData() : <></>}
    </Container>
  );
};

export default CustomCard;
